import './betanet-search.css';

import typeahead from 'typeahead-standalone';

const inputElements = document.querySelectorAll('.searchbar-input');

inputElements?.forEach((element) => {
    typeahead({
        input: element,
        source: {
            local: pages,
        },
        minLength: 2,
        limit: 9,
        display: (item, event) => {
            if (event && (event.key === 'Enter' || event.type === 'click')) {
                element.value = item.label;
                element.closest('form.searchbar').submit();
            }

            return `${item.label}`
        }
    });
});
